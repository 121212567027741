import { Component } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-algorithm',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './algorithm.component.html',
  styleUrl: './algorithm.component.css'
})
export class AlgorithmComponent {
  pdfFiles: { name: string, url: string, trustedUrl: SafeResourceUrl }[] = [];
  fullScreenPdf: { name: string, url: string, trustedUrl: SafeResourceUrl } | null = null; // For full-screen mode

  constructor(private sanitizer: DomSanitizer) {
    const pdfPaths = [
      { name: 'Diarrhoea Algorithm', url: '/assets/PPt/Diarrhoea-Algorithm.pdf' },
      { name: 'Pneumonia Algorithm', url: '/assets/PPt/Pneumonia-Algorithm.pdf' },
      { name: 'Vaccination Algorithm', url: '/assets/PPt/Vaccination-Plan.pdf' },
      { name: 'ANC Followup Algorithm', url: '/assets/PPt/ANCFollowupMIS.pdf' }
    ];

    // Sanitize each PDF file URL and add it to the pdfFiles array
    this.pdfFiles = pdfPaths.map(file => ({
      name: file.name,
      url: file.url,
      trustedUrl: this.sanitizer.bypassSecurityTrustResourceUrl(file.url)
    }));
  }

  // Open PDF in full-screen mode
  openPdf(pdf: { name: string, url: string, trustedUrl: SafeResourceUrl }) {
    this.fullScreenPdf = pdf; // Set the clicked PDF to full-screen mode
  }

  // Close full-screen PDF
  closeFullScreen() {
    this.fullScreenPdf = null; // Reset to close the full-screen view
  }
}
