import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import {BeneficiaryTrackerComponent} from './beneficiary-tracker/beneficiary-tracker.component';
import {DiarrheaComponent} from './diarrhea/diarrhea.component';
import {SelfCareComponent} from './self-care/self-care.component';
import {TeamTrackerComponent} from './team-tracker/team-tracker.component';
import {PneumoniaComponent} from './pneumonia/pneumonia.component'
import { DiarrheaImpactComponent } from './diarrhea-impact/diarrhea-impact.component';
import { TreeTablePageComponent } from './tree-table-page/tree-table-page.component';
import { DiarrheaImpactEngagedComponent } from './impact/diarrhea-impact-engaged/diarrhea-impact-engaged.component';
import { YogaMentalHealthComponent } from './impact/yoga-mental-health/yoga-mental-health.component';
// import { VectorBorneDiseasesComponent } from './impact/vector-borne-diseases/vector-borne-diseases.component';
import { PneumoniaImpactComponent } from './impact/pneumonia-impact/pneumonia-impact.component';
import { HygieneHealthImpactComponent } from './impact/hygiene-health-impact/hygiene-health-impact.component';
import { FoodHealthImpactComponent } from './impact/food-health-impact/food-health-impact.component';
import { AncImpactComponent } from './impact/anc-impact/anc-impact.component';
import { BirthSpacingComponent } from './birth-spacing/birth-spacing.component';
import { authGuard } from './guards/auth.guard';
import { ANCComponent } from './anc/anc.component';
import { FoodHealthComponent } from './food-health/food-health.component';
import { VectorBorneDiseasesComponent } from './vector-borne-diseases/vector-borne-diseases.component';
import { HygieneAndHealthComponent } from './hygiene-and-health/hygiene-and-health.component';
import { YogaAndMentalHealthComponent } from './yoga-and-mental-health/yoga-and-mental-health.component';
import { UserRequestFormComponent } from './user-request-form/user-request-form.component';
import { TeamPerformanceComponent } from './team-performance/team-performance.component';
import { AwarenessChangesComponent } from './components/awareness-changes/awareness-changes.component';
import { DiarrhoeaFunnelChartComponent } from './diarrhoea-funnel-chart/diarrhoea-funnel-chart.component';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { FunnelChartComponent } from './funnel-chart/funnel-chart.component';
import { ChildDiarrhoeaPneumoniaComponent } from './child-diarrhoea-pneumonia/child-diarrhoea-pneumonia.component';
import { MotherSixpillarsDashboardComponent } from './mother-sixpillars-dashboard/mother-sixpillars-dashboard.component';
import { LineListComponent } from './line-list/line-list.component';
import { CommunityInterventionComponent } from './community-intervention/community-intervention.component';
import { MultipleStackColumnWithlineComponent } from './multiple-stack-column-withline/multiple-stack-column-withline.component';
import { FamilyPlanningUsageAndAwarenessComponent } from './family-planning-usage-and-awareness/family-planning-usage-and-awareness.component';
import { DonutChartGeneratorComponent } from './donut-chart-generator/donut-chart-generator.component';
import { NewHygieneAndHealthComponent } from './new-hygiene-and-health/new-hygiene-and-health.component';
import { TestComponent } from './test/test.component';
import { AlgorithmComponent } from './algorithm/algorithm.component';
import { TeamPerformanceTableComponent } from './team-performance-table/team-performance-table.component';
import { FollowUpAnalysisComponent } from './follow-up-analysis/follow-up-analysis.component';



export const routes: Routes = [
  // 9 sept show form
  { path: 'userform', component: UserRequestFormComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      { path: 'home', component: HomeComponent, canActivate: [authGuard] },
      {
        path: 'diarrhoea-funnel-chart',
        component: DiarrhoeaFunnelChartComponent,
        canActivate: [authGuard],
      },
      {
        path: 'main-dashboard',
        component: DashboardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'app-new-dashboard',
        component: NewDashboardComponent,
        canActivate: [authGuard],
      },

      {
        path: 'child-diarrhoea-pneumonia',
        component: ChildDiarrhoeaPneumoniaComponent,
        canActivate: [authGuard],
      },
     
     
      {
        path: 'teamPerformance/:typewise',
        component: TeamPerformanceTableComponent,
        canActivate: [authGuard],
      },
      {
        path: 'test',
        component: TestComponent,
        canActivate: [authGuard],
      },
      {
        path: 'app-multiple-stack-column-withline',
        component: MultipleStackColumnWithlineComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mother-sixpillars-dashboard',
        component: MotherSixpillarsDashboardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'line-list',
        component: LineListComponent,
        canActivate: [authGuard],
      },
      {
        path: 'community-intervention',
        component: CommunityInterventionComponent,
        canActivate: [authGuard],
      },
      {
        path: 'algorithm',
        component: AlgorithmComponent,
        canActivate: [authGuard],
      },
      {
        path: 'app-funnel-chart',
        component: FunnelChartComponent,
        canActivate: [authGuard],
      },
      {
        path: 'beneficiary-tracker',
        component: BeneficiaryTrackerComponent,
        canActivate: [authGuard],
      },
      {
        path: 'family-planningUsage-and-awareness',
        component: FamilyPlanningUsageAndAwarenessComponent,
        canActivate: [authGuard],
      },
      {
        path: 'team-tracker',
        component: TeamTrackerComponent,
        canActivate: [authGuard],
      },
      {
        path: 'teamPreform',
        component: TeamPerformanceComponent,
        canActivate: [authGuard],
      },
      {
        path: 'new-hygiene-and-health',
        component: NewHygieneAndHealthComponent,
        canActivate: [authGuard],
      },
      {
        path: 'awarenessChanges',
        component: AwarenessChangesComponent,
        canActivate: [authGuard],
      },
      {
        path: 'diarrhea',
        component: DiarrheaComponent,
        canActivate: [authGuard],
      },
      {
        path: 'pneumonia',
        component: PneumoniaComponent,
        canActivate: [authGuard],
      },
      {
        path: 'self-care',
        component: SelfCareComponent,
        canActivate: [authGuard],
      },
      {
        path: 'tree-table-page',
        component: TreeTablePageComponent,
        canActivate: [authGuard],
      },
      {
        path: 'anc',
        component: ANCComponent,
        canActivate: [authGuard],
      },
      {
        path: 'food-health',
        component: FoodHealthComponent,
        canActivate: [authGuard],
      },
      {
        path: 'follow-up-analysis',
        component:FollowUpAnalysisComponent,
        canActivate: [authGuard],
      },
      {
        path: 'whopointindicator',
        component: FoodHealthImpactComponent,
        canActivate: [authGuard],
      },
      {
        path: 'vectorBorneDiseases',
        component: VectorBorneDiseasesComponent,
        canActivate: [authGuard],
      },
      {
        path: 'hygiene-health',
        component: HygieneAndHealthComponent,
        canActivate: [authGuard],
      },
      {
        path: 'yoga-mental-health',
        component: YogaAndMentalHealthComponent,
        canActivate: [authGuard],
      },
      {
        path: 'birth-spacing',
        component: BirthSpacingComponent,
        canActivate: [authGuard],
      },
      {
        path: 'impact',
        component: DiarrheaImpactComponent,
        children: [
          {
            path: 'diarrheaImpactEngagement',
            component: DiarrheaImpactEngagedComponent,
            canActivate: [authGuard],
          },
          {
            path: 'pneumoniaImpactEngagement',
            component: PneumoniaImpactComponent,
            canActivate: [authGuard],
          },
          {
            path: 'ancImpactEngagement',
            component: AncImpactComponent,
            canActivate: [authGuard],
          },
          {
            path: 'foodhealthImpactEngagement',
            component: FoodHealthImpactComponent,
            canActivate: [authGuard],
          },
          {
            path: 'vectorBorneImpactEngagement',
            component: VectorBorneDiseasesComponent,
            canActivate: [authGuard],
          },
          {
            path: 'hygieneHealthImpactEngagement',
            component: HygieneHealthImpactComponent,
            canActivate: [authGuard],
          },
          {
            path: 'yogaMentalHealthImpactEngagement',
            component: YogaMentalHealthComponent,
            canActivate: [authGuard],
          },
          {
            path: 'birth-spacingImpactEngagement',
            component: BirthSpacingComponent,
            canActivate: [authGuard],
          },
        ],
      },
      // Add other routes that should use the layout
    ],
  },
  { path: 'login', component: LoginComponent },

  { path: '**', redirectTo: '/home' },
];


