import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-follow-up-analysis',
  standalone: true,
  imports: [],
  templateUrl: './follow-up-analysis.component.html',
  styleUrl: './follow-up-analysis.component.css'
})
export class FollowUpAnalysisComponent {
  safePbiUrl: SafeResourceUrl;
  
  constructor(private sanitizer: DomSanitizer) {
    // Sanitize the Power BI URL to safely embed it
    const baseUrl = 'https://app.powerbi.com/view?r=eyJrIjoiMzYyZWY4ZGMtNGQ0Zi00Njg2LWEwNmQtODAwYTc3OGRlZDFkIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9';
                 
    this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl);
  }
}
