// import { Component, OnInit, ViewChild, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatSort } from '@angular/material/sort';
// import { MatPaginator } from '@angular/material/paginator';
// import { CustomLineChartComponent } from '../custom-line-chart/custom-line-chart.component';
// import { CommonModule, isPlatformBrowser } from '@angular/common';
// import { MatTableModule } from '@angular/material/table';
// import { MatSortModule } from '@angular/material/sort';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { FilterComponent } from '../filter/filter.component';

// interface BeneficiaryData {
//   firstName: string;
//   age: number;
//   mobile: string;
//   beneficiaryStatus: string;
//   fatherName: string;
//   husbandName: string;
// }

// @Component({
//   selector: 'app-beneficiary-tracker',
//   standalone: true,
//   imports: [CustomLineChartComponent, MatButtonModule, FilterComponent, MatIconModule, MatPaginatorModule, MatSortModule, MatTableModule, CommonModule],
//   templateUrl: './beneficiary-tracker.component.html',
//   styleUrls: ['./beneficiary-tracker.component.css']
// })
// export class BeneficiaryTrackerComponent implements OnInit, AfterViewInit {
//   displayedColumns2: string[] = ['sn', 'name', 'mobile', 'fatherName', 'husbandName'];
//   dataSource2: MatTableDataSource<BeneficiaryData>;
//   @ViewChild(MatSort) sort!: MatSort;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;

//   constructor(@Inject(PLATFORM_ID) private platformId: Object) {
//     this.dataSource2 = new MatTableDataSource<BeneficiaryData>([]);
//   }

//   ngOnInit(): void {
//     const exampleData: BeneficiaryData[] = [
//       { firstName: 'Bimla Devi', age: 30, mobile: '9834561234', beneficiaryStatus: 'LM', fatherName: 'Anand Singh', husbandName: 'Saroj Kumar' },
//     ];
//     this.dataSource2.data = exampleData;
//   }

//   ngAfterViewInit() {
//     if (isPlatformBrowser(this.platformId)) {
//       this.dataSource2.sort = this.sort;
//       this.dataSource2.paginator = this.paginator;
//     }
//   }

//   applyFilter(event: Event) {
//     const filterValue = (event.target as HTMLInputElement).value;
//     this.dataSource2.filter = filterValue.trim().toLowerCase();
//     if (this.dataSource2.paginator) {
//       this.dataSource2.paginator.firstPage();
//     }
//   }

//   onFilterChange(filterValues: any): void {
//     this.fetchDataBasedOnLocation(filterValues);
//   }

//   private fetchDataBasedOnLocation(filterValues: any): void {
//     const { state_Id, district_Id, block_Id, village_Id, from_date, to_date } = filterValues;
   
//     // Implement your data fetching logic here based on the filter values
//   }

//   export() {
//     console.log('Exporting data...');
//   }
// }

import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-beneficiary-tracker',
  standalone: true,
  imports: [],
  templateUrl: './beneficiary-tracker.component.html',
  styleUrls: ['./beneficiary-tracker.component.css']
})
export class BeneficiaryTrackerComponent {
  safePbiUrl: SafeResourceUrl;
  
  constructor(private sanitizer: DomSanitizer) {
    // Sanitize the Power BI URL to safely embed it
    const baseUrl = 'https://app.powerbi.com/view?r=eyJrIjoiMzYyZWY4ZGMtNGQ0Zi00Njg2LWEwNmQtODAwYTc3OGRlZDFkIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9';
                    //  https://app.powerbi.com/view?r=eyJrIjoiNjNhMjNlOTMtNGNmMC00NWEyLTgzYWUtYzQ0ZTVmMGJhOTI3IiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9
    // const urlWithParams = `${baseUrl}&pageName=ReportSection1`; // Change ReportSection1 to match your actual first page name
    
    this.safePbiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl);
  }
 }