import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment.local';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunityInterventionService {
  private readonly motherMeetingsEndpoint = `${environment.apiUrl}MotherMeetings`;
  private readonly schoolEndpoint = `${environment.apiUrl}schoolHygienes`;
  private readonly eventEndpoint = `${environment.apiUrl}events`;
  private readonly nukkadNatakEndpoint = `${environment.apiUrl}NukkadNataks`;
  private readonly IecEndpoint = `${environment.apiUrl}IECDisseminations`;

  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  getMeetingData(params: any = {}): Observable<any> {
    return this.http.get(`${this.motherMeetingsEndpoint}/getMeetingData`, {
      params: new HttpParams().set('params', JSON.stringify(params)),
    });
  }

  getMotherMeetingData(params: any = {}): Observable<any> {
    const url = `${this.motherMeetingsEndpoint}/getMotherMeetingData`;
    return this.http.get(url,  {
      params: new HttpParams().set('params', JSON.stringify(params))
    });
  }

  getSchoolVisitData(params: any = {}): Observable<any> {
    const url = `${this.schoolEndpoint}/getSchoolVisitData`;
    return this.http.get(url, {
      params: new HttpParams().set('params', JSON.stringify(params))
    });
  }

  getNukkadNatakData(params: any = {}): Observable<any> {
    const url = `${this.nukkadNatakEndpoint}/getNukkadnatakData`;
    return this.http.get(url, {
      params: new HttpParams().set('params', JSON.stringify(params))
    });
  }

  getEventData(params: any = {}): Observable<any> {
    const url = `${this.eventEndpoint}/getEventData`;
    return this.http.get(url,  {
      params: new HttpParams().set('params', JSON.stringify(params))
    });
  }
  getIecDiesseminationData(params: any = {}): Observable<any> {
    const url = `${this.IecEndpoint}/getIecDisseminationData`;
    return this.http.get(url,  {
      params: new HttpParams().set('params', JSON.stringify(params))
    });
  }
}
