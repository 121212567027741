import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FunnelChartComponent } from '../funnel-chart/funnel-chart.component'; // Import the funnel chart component
import { TeamPerformanceComponent } from '../team-performance/team-performance.component';
import { StackedColumnChartComponent } from '../stacked-column-chart/stacked-column-chart.component';
import { MultipleStackColumnWithlineComponent } from '../multiple-stack-column-withline/multiple-stack-column-withline.component';
import {
  MedicineAvailability,
  StackedDataPoint,
  StackedDataPoint33,
  ApiParamsForReach,
} from './new-dashboard-interface';
import { NewOverviewService } from '../Services/new-OverviewService/new-overview.service';
import { OverviewDashboardService } from '../Services/overview-dashboard.service';
import { CommonModule } from '@angular/common';
import { FilterComponent } from '../filter/filter.component';
import { ColumnChartComponent } from '../column-chart/column-chart.component';
import { TargetService } from '../Services/target.service';
import { StepChartComponent } from '../step-chart/step-chart.component';
import { array } from '@amcharts/amcharts5';
import { StackchartComponent } from '../stackchart/stackchart.component';

interface ChartData {
  months: string[];
  gujaratData: number[];
  maharashtraData: number[];
  rajasthanData: number[];
  targetValueGujarat?: number | null;
  targetValueMaharashtra?: number | null;
  targetValueRajasthan?: number | null;
  colors?: {
    incomeColor: string;
    expensesColor: string;
    savingsColor: string;
  };
}

@Component({
  selector: 'app-new-dashboard',
  standalone: true,
  imports: [
    TeamPerformanceComponent,
    FunnelChartComponent,
    StackedColumnChartComponent,
    MultipleStackColumnWithlineComponent,
    CommonModule,
    FilterComponent,
    ColumnChartComponent,
    StepChartComponent,
    StackchartComponent
  ], // Add FunnelChartComponent here
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css'],
})
export class NewDashboardComponent implements OnInit {

  showFilter: boolean = false; // Controls the visibility of <app-filter>
  variableToStoreDiarrhoea0IndexLabel: any;
  variableToStoreDiarrhoea0IndexCount: any;

  // Toggles the visibility
  toggleFilter() {
    this.showFilter = !this.showFilter;
  };

  // Handle filter change event
  onFilterGraphChange(event: any) {
    console.log('Filter changed:', event);
    // Handle the event logic here
  };

  stateId: any[] = [];
  districtId: any[] = [];
  blockId: any[] = [];
  villageId: any[] = [];
  chwId: any[] = [];
  currentUser: any;
  fromDate: Date | null = null;
  toDate: Date | null = null;
  isApproval: boolean = false;
  funnelChartColorsOfDiarrhoeaCases = [
    '#ADD8E6',
    '#FFD700',
    '#98FB98',
    '#D3D3D3',
    '#D19CFF', // Lavender (Darker shade of Light Lavender)
  ];

  funnelChartColorsOfPneumoniaCases = [
    '#FFB6C1', // Light Pink
    '#F0E68C', // Khaki
    '#87CEEB', // Sky Blue
    '#B0E0E6', // Powder Blue
    '#40E0D0', // Turquoise
  ];

  // Store chart data for multiple charts ofHH COverage
  hhCOverageData: ChartData = {
    months: [],
    gujaratData: [],
    maharashtraData: [],
    rajasthanData: [],
    targetValueGujarat: 3080,
    targetValueMaharashtra: 5280,
    targetValueRajasthan: 3080,
    colors: {
      incomeColor: '#FF6600',
      expensesColor: '#2F80ED',
      savingsColor: '#F1C40F',
    },
  };
  // Store chart data for multiple charts of MothersData
  MothersData: ChartData = {
    months: [],
    gujaratData: [],
    maharashtraData: [],
    rajasthanData: [],
    targetValueGujarat: 2380, // Example target value for Gujarat
    targetValueMaharashtra: 4080, // Example target value for Maharashtra
    targetValueRajasthan: 2380, // Example target value for Rajasthan
    colors: {
      incomeColor: '#FF6600',
      expensesColor: '#2F80ED',
      savingsColor: '#F1C40F',
    },
  };
  // Store chart data for multiple charts of ChildData
  ChildData: ChartData = {
    months: [],
    gujaratData: [],
    maharashtraData: [],
    rajasthanData: [],
    targetValueGujarat: 1680, // Example target value for Gujarat
    targetValueMaharashtra: 2880, // Example target value for Maharashtra
    targetValueRajasthan: 1680, // Example target value for Rajasthan
    colors: {
      incomeColor: '#FF6600',
      expensesColor: '#2F80ED',
      savingsColor: '#F1C40F',
    },
  };

  // Flag to track when data is fully loaded
  dataLoadedForHHCoverage: boolean = false;
  dataLoadedForMothers: boolean = false;
  dataLoadedForChild: boolean = false;
  dataLoadedResponseForChild: boolean = false;
  dataLoadedResponseForMothers: boolean = false;
  dataLoadedResponseForHHCoverage: boolean = false;

  // child target
  childDistricts = ['Gujarat Target', 'Maharashtra Target', 'Rajasthan Target'];
  tableDataforMonthDetails = [
    {
      // title: '50',
      values: [1680, 2880, 1680],
    },
    // You can add more rows if needed
  ];

  // mother target
  childDistricts2 = [
    'Gujarat Target',
    'Maharashtra Target',
    'Rajasthan Target',
  ];
  tableDataforMonthDetails2 = [
    {
      // title: '50',
      values: [2380, 4080, 2380],
    },
  ];

  //HH Coverage
  childDistricts3 = [
    'Gujarat Target',
    'Maharashtra Target',
    'Rajasthan Target',
  ];
  tableDataforMonthDetails3 = [
    {
      // title: '50',
      values: [3080, 5280, 3080],
    },
  ];

  monthNames = ['September', 'October', 'November', 'December']; // Define month names

  availabiliAtFacilityData: MedicineAvailability[] = [];
  // for mother column chart
  awarenessAndPractice: StackedDataPoint[] = []; // Empty array to store chart data from API
  colors = ['#4CAF50', '#FFC107', '#2196F3'];
  chartData2: ChartData = {
    months: ['Jan', 'Feb', 'Mar'],
    gujaratData: [75, 82, 90, 95],
    maharashtraData: [80, 88, 95],
    rajasthanData: [70, 78, 85],
    targetValueGujarat: 100,
    targetValueMaharashtra: 110,
    targetValueRajasthan: 95,
    colors: {
      incomeColor: '#4CAF50',
      expensesColor: '#2196F3',
      savingsColor: '#FFC107',
    },
  };
  isLoading!:boolean

  chartData3: ChartData = {
    months: ['Jan', 'Feb', 'Mar'],
    gujaratData: [75, 82, 90, 95],
    maharashtraData: [80, 88, 95],
    rajasthanData: [70, 78, 85],
    targetValueGujarat: 100,
    targetValueMaharashtra: 110,
    targetValueRajasthan: 95,
    colors: {
      incomeColor: '#4CAF50',
      expensesColor: '#2196F3',
      savingsColor: '#FFC107',
    },
  };

  // Data for Diarrhoea Cases Funnel Chart
  diarrhoeaFunnelChartData: any[] = [];
  pneumoniaFunnelChartData: any[] = [];
  isLoadingforfunnelChartDataForDiarrhoea = true;
  isLoadingforfunnelChartDataForPneumonia = true;
  variableToStorePneumonia0IndexLabel: string = 'N/A';
  variableToStorePneumonia0IndexCount: number = 0;
  variableToStorePneumonia1IndexLabel: string = 'N/A';
  variableToStorePneumonia1IndexCount: number = 0;
  suspectedPneumoniaCases: number = 0;
  referredToFacilityCases: number = 0;
  recoveredPostReferralCases: number = 0;
  notReferredToFacilityCases: number = 0;
  recoveredAtHomeCases: number = 0;
  pneumoniaMortalityCases: number = 0;
  fullyRecoveredPneumoniaLabel: string = 'Fully Recovered';
  fullyRecoveredPneumoniaCount: number = 0; 

  reachApiDataStore: any[] = [];
  TargetTileDataStore: any[] = [
    { label: 'Household Registration', value: '15340' },
    { label: 'Mother Registration', value: '10532' },
    { label: 'Child Registration', value: '13166' },
    { label: 'Mother Meetings', value: '327' },
    { label: 'Health Assessment', value: '4' },
  ];

  dataLoadedResponseForReach: boolean = false;
  storeInformedData: any[] = [];
  storeEngagedData: any[] = [];
  staticVillages: number = 210;
  staticVillagesCondition: boolean = true
  targetApiDataStore: any = null;
  params: any = {};
  targetVsAchivementStateWise: any;
  targetVsAchivementDistrictwise: any;
  targetVsAchivementStateWiseMonthwise: any;
  targetVsAchivementDistrictWiseMonthwise: any;
  targetVsAchivementBlockWiseMonthwise: any;
  statewiseColors: string[] = ['#CC5A4A', '#4ACC5A', '#4A5ACC'];
  blockwiseColors: string[] = ['#CC5A4A', '#4ACC5A', '#4A5ACC'];
  districtwiseColors: string[] = [
    '#CC5A4A',
    '#CC5A4A',
    '#4ACC5A',
    '#4ACC5A',
    '#4A5ACC',
  ];
  DiarrhoeaFullyRecoveredLabel: any;
  DiarrhoeaFullyRecoveredCount: any;
  PneumoniaFullyRecoveredLabel: any;
  PneumoniaFullyRecoveredCount: any;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private OverviewDashboardService: OverviewDashboardService,
    private newOverviewService: NewOverviewService,
    private cdr: ChangeDetectorRef,
    public targetService: TargetService,
    private overviewDashboardService: OverviewDashboardService,
  ) {}

  ngOnInit() {
    //this.loadTeamPerformanceComponent();


/**For filter dynamic apply based on params pass  */


    this.loadReachData(this.params);
    this.loadInformedData(this.params);
    this.loadEngagedData(this.params);
    this.loadAwarenessPracticeData(this.params);
    this.loadDiarrhoeaVerticleData(this.params);
    this.loadPneumoniaVerticleData(this.params);
    this.loadAvailabilityAtFacilityData(this.params);
    this.loadHHCOverageData();
    this.loadMothersData();
    this.loadChildData();
    this.getDataFromLocalStorage();
    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'statewise',
        designation:"Admin"
      })
      .subscribe((res: any) => {
        this.targetVsAchivementStateWise = res.map((item: any) => ({
          ...item,
          value: item.score,
        }));
      });
    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'districtwise',
        designation:"SM"
      })
      .subscribe((res: any) => {
        this.targetVsAchivementDistrictwise = res.map((item: any) => ({
          ...item,
          value: item.score,
        }));
      });
    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'statewise-monthwise',
        designation:"Admin"
      })
      .subscribe((res) => {
        this.targetVsAchivementStateWiseMonthwise = res;
      });

    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'districtwise-monthwise',
        designation:"SM"
      })
      .subscribe((res) => {
        this.targetVsAchivementDistrictWiseMonthwise = res;
      });

    this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'blockwise-monthwise',
      })
      .subscribe((res) => {
        console.log('Result New.................block', res);
        this.targetVsAchivementBlockWiseMonthwise = res;
        this.cdr.detectChanges();
        console.log(
          'targetVsAchivementBllockWiseMonthwise',
          this.targetVsAchivementBlockWiseMonthwise
        );
      });

      this.OverviewDashboardService.params$.subscribe((params) => {
        this.params = params; 
        if (params) {
        this.functionFilterFromOverview(params);
           }
    });
  }




  functionFilterFromOverview(params: any): void {
     const filterParams = {
      stateId: params.stateId, 
      districtId: params.districtId,
      blockId: params.blockId, 
      fromDate: params.fromTimestamp,
      toDate: params.toTimestamp,
    };
      this.getAllData(filterParams);
      setTimeout(() => {
        this.staticVillagesCondition = false;
      }, 1000); 
   }

  // loadTeamPerformanceComponent(): void {
  //   this.pneumoniaContainer.clear();
  //   const componentRef: ComponentRef<TeamPerformanceComponent> =
  //     this.pneumoniaContainer.createComponent(TeamPerformanceComponent);

  //   // Hide the tables (set showTables to false)
  //   componentRef.instance.showTables = false;
  // }

  getDataFromLocalStorage(): void {
    const allUser = localStorage.getItem('currentUser');

    if (allUser) {
      const currentUser: any = JSON.parse(allUser);

      switch (currentUser.user.designation) {
        case 'SM':
          this.stateId = currentUser.user['stateId'];
          break;
        case 'DL':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          break;
        case 'BO':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          this.blockId = currentUser.user['blockId'];
          break;
        case 'CHW':
          this.stateId = currentUser.user['stateId'];
          this.districtId = currentUser.user['districtId'];
          this.blockId = currentUser.user['blockId'];
          this.villageId = currentUser.user['villageId'];
          this.chwId = currentUser.user['chwId'];
          break;
      }
    }
    this.params = {
      stateId: Array.isArray(this.stateId) ? this.stateId : [this.stateId],
      districtId: Array.isArray(this.districtId)
        ? this.districtId
        : [this.districtId],
      blockId: Array.isArray(this.blockId) ? this.blockId : [this.blockId],
      villageId: Array.isArray(this.villageId)
        ? this.villageId
        : [this.villageId],
      chwId: Array.isArray(this.chwId) ? this.chwId : [this.chwId],
      userLevel: this.currentUser?.user.designation,
    };
    this.getAllData(this.params);
  }

  getAllData(paramsValue: any): void {
    this.loadReachData(paramsValue);
    this.loadInformedData(paramsValue);
    this.loadEngagedData(paramsValue);
    // this.loadAwarenessAndPracticeData(paramsValue);
    this.loadDiarrhoeaVerticleData(paramsValue);
    this.loadPneumoniaVerticleData(paramsValue);
    this.loadTargetOverview(paramsValue);
    // this.loadAvailabilityAtFacilityData(paramsValue);
    // this.loadAwarenessPracticeData(paramsValue);
  }
  loadGetTargetOnMonth(paramsValue: any = this.params): void {
      this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'statewise-monthwise',
        designation:"Admin",
        ...paramsValue
      })
      .subscribe((res) => {
        this.targetVsAchivementStateWiseMonthwise = res;
      });
  }
  loadTargetDistrictWise(paramsValue: any = this.params){
  this.targetService
      .getTargetVsAchivementMonthOnMonth({
        groupBy: 'districtwise-monthwise',
        designation:"SM",
          ...paramsValue
      })
      .subscribe((res) => {
        this.targetVsAchivementDistrictWiseMonthwise = res;
      });
  }

  loadReachData(params: any = this.params): void {
    this.dataLoadedResponseForReach = true;
    this.newOverviewService.getReachData(params).subscribe(
      (res: any) => {
        // Map the response to include the label
        this.reachApiDataStore = res.data.map((item: any) => ({
          label: item.name, // Set the label to the name from the response
          value: item.value, // Set the value
        }));
        this.dataLoadedResponseForReach = false;
      },
      (err: any) => {
        console.error('Error occurred in reach:', err);
        this.dataLoadedResponseForReach = false;
      }
    );
  }

  loadTargetOverview(params: any = this.params) {
    // this.dataLoadedtarget = true;
    this.targetService.getTargetOverview(params).subscribe(
      (res: any) => {
        this.targetApiDataStore = Object.fromEntries(
          Object.entries(res).map(([key, value]) =>
            typeof value === 'number' ? [key, Math.round(value)] : [key, value]
          )
        );
        // this.dataLoadedtarget = false;
      },
      (err: any) => {
        console.error('Error occurred in reach:', err);
        // this.dataLoadedtarget = false;
      }
    );
  }
  

  loadInformedData(params: any = this.params): void {
    this.newOverviewService.getInformedData(params).subscribe(
      (res: any) => {
        this.storeInformedData = res.data.map((item: any) => ({
          label: item.name,
          value: item.value,
        }));
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaCasesBifurcation:', err);
      }
    );
  }

  loadEngagedData(params: any = this.params): void {
    this.newOverviewService.getEngagedData(params).subscribe(
      (res: any) => {
        this.storeEngagedData = res.data.map((item: any) => ({
          label: item.name,
          value: item.value,
        }));
      },
      (err: any) => {
        console.error('Error occurred in getDiarrheaCasesBifurcation:', err);
      }
    );
  }

  // loadAwarenessAndPracticeData(params: any = this.params): void {
  //   // Call the service and pass parameters to it
  //   this.newOverviewService.getAwarenessAndPractice(params).subscribe(
  //     (res: any) => {
  //       // Map the API response to your chart data structure
  //       this.awarenessAndPractice = res
  //     },
  //     (err: any) => {
  //       console.error(
  //         'Error occurred while fetching awareness and practice data:',
  //         err
  //       );
  //     }
  //   );
  // }

  loadAwarenessPracticeData(params: any = this.params): void {
    // Call the service and pass parameters to it
    this.newOverviewService.getAwarenessPracticeGraph(params).subscribe(
      (res: any) => {
        // Map the API response to your chart data structure
        this.awarenessAndPractice = res
      },
      (err: any) => {
        console.error(
          'Error occurred while fetching awareness and practice data:',
          err
        );
      }
    );
  }


  loadAvailabilityAtFacilityData(params: any = this.params): void {
    // Call the service and pass parameters to it
    this.OverviewDashboardService.getAvailabilityAtFacility(params).subscribe(
      (res: any) => {
        this.availabiliAtFacilityData = res;
        console.log("data passing to the Health Facility Graph", res)
      },
      (err: any) => {
        console.error(
          'Error occurred while fetching awareness and practice data:',
          err
        );
      }
    );
  }

  loadDiarrhoeaVerticleData(params: any = this.params) {
    this.isLoadingforfunnelChartDataForDiarrhoea = true;
    this.newOverviewService.getDiarrhoeaCounts(params).subscribe(
      (response) => {
        if (response && response.data) {
          // Map data for funnel chart
          this.diarrhoeaFunnelChartData = response.data.map((item: any) => ({
            value: item.count, // Value for the chart
            category: item.label, // Category name for the chart
          }));
  
          // Store label and count for the first and last index
          if (response.data.length > 0) {
            this.variableToStoreDiarrhoea0IndexLabel = response.data[0].label; // First label
            this.variableToStoreDiarrhoea0IndexCount = response.data[0].count; // First count
            this.DiarrhoeaFullyRecoveredLabel = response.data[response.data.length - 1].label; // Last label
            this.DiarrhoeaFullyRecoveredCount = response.data[response.data.length - 1].count; // Last count
          }
  
          // Remove first and last items for funnel chart data
          this.diarrhoeaFunnelChartData = this.diarrhoeaFunnelChartData.slice(1, response.data.length - 1);
        } else {
          this.diarrhoeaFunnelChartData = [];
        }
      },
      (error) => {
        this.diarrhoeaFunnelChartData = [];
      },
      () => {
        this.isLoadingforfunnelChartDataForDiarrhoea = false;
      }
    );
  }
  


loadPneumoniaVerticleData(params: any = this.params) {
  this.newOverviewService.getPneumoniaCounts(params).subscribe({
    next: (response) => {
      if (response && response.data) {
        // Create a map for easier data lookup
        const dataMap = response.data.reduce((acc:any, item:any) => {
          acc[item.label] = item.count;
          return acc;
        }, {});

        // Populate variables for the first two labels in the grid
        this.variableToStorePneumonia0IndexLabel = response.data[0].label;
        this.variableToStorePneumonia0IndexCount = response.data[0].count;
        this.variableToStorePneumonia1IndexLabel = response.data[1].label;
        this.variableToStorePneumonia1IndexCount = response.data[1].count;

        // Populate table data
        this.suspectedPneumoniaCases = dataMap['SuspectedPneumoniaCases'] || 0;
        this.referredToFacilityCases = dataMap['Referred To Facility'] || 0;
        this.pneumoniaMortalityCases = dataMap['Pneumonia Mortality'] || 0;

        // Additional derived or default values
        this.recoveredPostReferralCases = dataMap['Treated At Facility'] || 0;  // Not present in the current response
        this.notReferredToFacilityCases = dataMap['Not Referred To Facility'] || 0; // Not present in the current response
        this.recoveredAtHomeCases = dataMap['Treated At Home'] || 0; // Not present in the current response

        // Fully recovered section
        this.fullyRecoveredPneumoniaLabel = 'Fully Recovered';
        this.fullyRecoveredPneumoniaCount = dataMap['Fully Recovered'] || 0;

        // Store full chart data if needed
        this.pneumoniaFunnelChartData = response.data;
      } else {
        // Reset all variables if no data
        this.resetVariables();
      }
    },
    error: (error) => {
      console.error('Error fetching pneumonia data:', error);
      this.resetVariables();
    },
    complete: () => {
      // this.isLoading = false;
    }
  });
}

// Helper method to reset all variables
private resetVariables(): void {
  this.variableToStorePneumonia0IndexLabel = 'N/A';
  this.variableToStorePneumonia0IndexCount = 0;
  this.variableToStorePneumonia1IndexLabel = 'N/A';
  this.variableToStorePneumonia1IndexCount = 0;

  this.suspectedPneumoniaCases = 0;
  this.referredToFacilityCases = 0;
  this.recoveredPostReferralCases = 0;
  this.notReferredToFacilityCases = 0;
  this.recoveredAtHomeCases = 0;
  this.pneumoniaMortalityCases = 0;

  this.fullyRecoveredPneumoniaLabel = 'Fully Recovered';
  this.fullyRecoveredPneumoniaCount = 0;

  this.pneumoniaFunnelChartData = [];
}
  

  // this is a funaction to handle HH coverage api coming from service file
  loadHHCOverageData() {
    this.dataLoadedResponseForHHCoverage = false; // Start loading
    this.newOverviewService.getHHCoverage(this.params).subscribe(
      (response: any) => {
        const monthDataArray = response?.data; // Get the full month data array

        if (monthDataArray && monthDataArray.length > 0) {
          // Iterate through each month's data
          monthDataArray.forEach((monthData: any) => {
            const statesData = monthData?.states;
            const month = monthData?.month;

            // Log states data for debugging purposes

            // Assign the month name to the months array
            this.hhCOverageData.months.push(month || 'Unknown Month'); // Push month name or fallback

            if (statesData && statesData.length > 0) {
              // Update the chart data based on the state data dynamically
              statesData.forEach((state: any) => {
                if (state.stateName === 'Gujarat') {
                  this.hhCOverageData.gujaratData.push(state.count); // Income data for Gujarat
                }
                if (state.stateName === 'Maharashtra') {
                  this.hhCOverageData.maharashtraData.push(state.count); // Expenses data for Maharashtra
                }
                if (state.stateName === 'Rajasthan') {
                  this.hhCOverageData.rajasthanData.push(state.count); // Savings data for Rajasthan
                }
              });
            }
          });

          // Log the final chart data for debugging purposes

          // Mark data as fully loaded
          this.dataLoadedForHHCoverage = true;

          // Trigger change detection to update the view
          this.cdr.detectChanges();
        }
        this.dataLoadedResponseForHHCoverage = false; // Stop loading when data is loaded
      },
      (error) => {
        console.error('Error fetching data from API', error);
        this.dataLoadedResponseForHHCoverage = false; // Stop loading on error
      }
    );
  }

  // this is a funaction to handle Mothers api coming from service file
  loadMothersData() {
    this.dataLoadedResponseForMothers = true; // Start loading
    this.newOverviewService.getMothersData(this.params).subscribe(
      (response: any) => {
        // Log the full response to the console

        // Safely access the data using optional chaining
        const monthDataArray = response?.data; // Get the full month data array

        if (monthDataArray && monthDataArray.length > 0) {
          // Iterate through each month's data
          monthDataArray.forEach((monthData: any) => {
            const statesData = monthData?.states;
            const month = monthData?.month;

            // Log states data for debugging purposes

            // Assign the month name to the months array
            this.MothersData.months.push(month || 'Unknown Month'); // Push month name or fallback

            if (statesData && statesData.length > 0) {
              // Update the chart data based on the state data dynamically
              statesData.forEach((state: any) => {
                if (state.stateName === 'Gujarat') {
                  this.MothersData.gujaratData.push(state.count); // Income data for Gujarat
                }
                if (state.stateName === 'Maharashtra') {
                  this.MothersData.maharashtraData.push(state.count); // Expenses data for Maharashtra
                }
                if (state.stateName === 'Rajasthan') {
                  this.MothersData.rajasthanData.push(state.count); // Savings data for Rajasthan
                }
              });
            }
          });

          // Log the final chart data for debugging purposes

          // Mark data as fully loaded
          this.dataLoadedForMothers = true;

          // Trigger change detection to update the view
          this.cdr.detectChanges();
        }
        this.dataLoadedResponseForMothers = false; // Stop loading when data is loaded
      },
      (error) => {
        console.error('Error fetching data from API', error);
        this.dataLoadedResponseForMothers = false; // Stop loading when data is loaded
      }
    );
  }

  // this is a funaction to handle Mothers api coming from service file
  loadChildData() {
    this.dataLoadedResponseForChild = true; // Start loading
    this.newOverviewService.getChildData(this.params).subscribe(
      (response: any) => {
        // Log the full response to the console

        // Clear existing data before pushing new values
        this.ChildData.months = [];
        this.ChildData.gujaratData = [];
        this.ChildData.maharashtraData = [];
        this.ChildData.rajasthanData = [];

        // Iterate over the entire data array
        response?.data?.forEach((monthData: any) => {
          const statesData = monthData?.states;
          const month = monthData?.month;

          if (statesData && statesData.length > 0) {
            // Push the month name to the months array
            this.ChildData.months.push(month || 'Unknown Month');

            // Iterate over the states data for each month and push values
            statesData.forEach((state: any) => {
              if (state.stateName === 'Gujarat') {
                this.ChildData.gujaratData.push(state.count); // Gujarat data
              }
              if (state.stateName === 'Maharashtra') {
                this.ChildData.maharashtraData.push(state.count); // Maharashtra data
              }
              if (state.stateName === 'Rajasthan') {
                this.ChildData.rajasthanData.push(state.count); // Rajasthan data
              }
            });
          }
        });

        // Log the final chart data for debugging purposes

        // Mark data as fully loaded
        this.dataLoadedForChild = true;

        // Trigger change detection to update the view
        this.cdr.detectChanges();

        this.dataLoadedResponseForChild = false; // Stop loading when data is loaded
      },
      (error) => {
        console.error('Error fetching data from API', error);
        this.dataLoadedResponseForChild = false; // Stop loading when there is an error
      }
    );
  }

  // on filter change API Calls
  onFilterChange(event: any): void {
    event['type'] = 'filter';
  }

  
}
