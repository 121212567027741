import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-awareness-changes',
  standalone: true,
  imports: [],
  templateUrl: './awareness-changes.component.html',
  styleUrls: ['./awareness-changes.component.css']
})
export class AwarenessChangesComponent {
  private readonly DIARRHOEA_URL = 'https://app.powerbi.com/view?r=eyJrIjoiOGU5ZGQwZGEtN2JkNi00ZWMwLWI5NTktOTZmZDBiNzcyMWEwIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9&pageName=d4068b894bf796dae882';
  private readonly PNEUMONIA_URL = 'https://app.powerbi.com/view?r=eyJrIjoiNGFlYzYwZjQtNGMzNC00ZDg4LThmN2EtYjA4N2YyODM0YmEzIiwidCI6ImRkNjMwMGViLTRmZmUtNDc1Mi1hYThiLTNjNTQ1YzczZTA3MCJ9';
  
  currentReportUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    // Set default URL to Diarrhoea report
    this.currentReportUrl = this.sanitizeUrl(this.DIARRHOEA_URL);
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  switchReport(report: 'diarrhoea' | 'pneumonia'): void {
    const url = report === 'diarrhoea' ? this.DIARRHOEA_URL : this.PNEUMONIA_URL;
    this.currentReportUrl = this.sanitizeUrl(url);
  }

  openInNewWindow() {
    window.open('/awarenessChanges', '_blank');
  }
}