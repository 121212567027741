// import { Component, OnInit, OnDestroy } from '@angular/core';
// import { CommunityInterventionService } from '../Services/community-intervention.service';
// import { CommunityInterventionStackGraphComponent } from "../community-intervention-stack-graph/community-intervention-stack-graph.component";
// import { ClusterCommunityInterventionStackChartComponent } from "../cluster-community-intervention-stack-chart/cluster-community-intervention-stack-chart.component";
// import { CommonModule } from '@angular/common';
// import { catchError, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
// import { 
//   MeetingData, 
//   MotherMeetingTopics,
//   SchoolData,
//   NukkedNatakData,
//   EventData,
//   StateData,
//   MotherMeetingResponse,
//   MeetingResponse
// } from './community-intervention.types';
// import { FilterComponent } from "../filter/filter.component";
// import { OverviewDashboardService } from '../Services/overview-dashboard.service';

// @Component({
//   selector: 'app-community-intervention',
//   standalone: true,
//   imports: [
//     CommunityInterventionStackGraphComponent,
//     ClusterCommunityInterventionStackChartComponent,
//     CommonModule,
//     FilterComponent
// ],
//   templateUrl: './community-intervention.component.html',
//   styleUrls: ['./community-intervention.component.css'],
// })
// export class CommunityInterventionComponent implements OnInit, OnDestroy {
//   states: string[] = ['Maharashtra', 'Gujarat', 'Rajasthan'];
  
//   // Data properties
//   meetings: MeetingResponse[] = [];
//   meetingsData: MeetingData[] = [];
//   motherMeetingTopics: Record<string, MotherMeetingTopics> = {};
//   schoolData: SchoolData[] = [];
//   nukkadNatak: NukkedNatakData[] = [];
//   nukkedNatakData: NukkedNatakData[] = [];
//   eventData: EventData[] = [];
//   currentUser: any;
//   params: any = {};

//   // UI state properties
//   loading = false;
//   error: string | null = null;

//   private destroy$ = new Subject<void>();

//   constructor(
//     private communityService: CommunityInterventionService,
//     private overviewDashboardService: OverviewDashboardService,
//   ) {}

//   ngOnInit(): void {
//     this.loading = true;
//     this.loadData();

//     this.overviewDashboardService.params$.subscribe((params) => {
//       this.params = params;
//       if (params) {
//         this.functionFilter(params);
//       }
//     });

//   }

//   ngOnDestroy(): void {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   private loadData(params: any = this.params): void {
//     forkJoin({
//       meetings: this.communityService.getMeetingData(params),
//       motherMeetings: this.communityService.getMotherMeetingData(params),
//       schoolVisits: this.communityService.getSchoolVisitData(params),
//       nukkadNatak: this.communityService.getNukkadNatakData(params),
//       events: this.communityService.getEventData(params)

//     }).pipe(
//       takeUntil(this.destroy$),
//       catchError(error => {
//         this.error = 'Failed to load data. Please try again later.';
//         console.error('Data loading error:', error);
//         this.loading = false;
//         throw error;
//       })
//     ).subscribe({
//       next: (data) => {
//         this.processData(data);
//         this.loading = false;
//       },
//       error: () => {
//         this.loading = false;
//       }
//     });
//   }

//   private processData(data: any): void {

//     // meeting data 
//     if (Array.isArray(data.meetings.data)) {
//       const meetings = data.meetings.data as MeetingResponse[];
//       this.meetings = meetings.map(meeting => ({
//         name: meeting.name,
//         totalMeeting: meeting.totalMeeting,
//         motherMeetingCount: meeting.motherMeetingCount,
//         communityMeetingCount: meeting.communityMeetingCount,
//         vhsndMeeting: meeting.vhsndMeeting,
//         panchayatGharMeetingCount: meeting.panchayatGharMeetingCount,
//         totalPopulationCovered: meeting.totalPopulationCovered,
//         motherMeetingPopulationCovered: meeting.motherMeetingPopulationCovered,
//         communityMeetingPopulationCovered: meeting.communityMeetingPopulationCovered,
//         vhsndMeetingPopulationCovered: meeting.vhsndMeetingPopulationCovered,
//         panchayatGharMeetingPopulationCovered: meeting.panchayatGharMeetingPopulationCovered,
//         totalFemaleCovered: meeting.totalFemaleCovered,
//         motherMeetingFemaleCovered: meeting.motherMeetingFemaleCovered,
//         communityMeetingFemaleCovered: meeting.communityMeetingFemaleCovered,
//         vhsndFemaleCovered: meeting.vhsndFemaleCovered,
//         panchayatGharMeetingFemaleCovered: meeting.panchayatGharMeetingFemaleCovered,
//       }));
//     } else {
//       console.error("data.meetings is not an array:", data.meetings);
//     }

//     // Process mother meetings data
//     if (data.motherMeetings) {
//       const motherMeetings = data.motherMeetings as MotherMeetingResponse[];
      
//       this.meetingsData = motherMeetings.map(meeting => ({
//         state: meeting.name,
//         totalMeetings: meeting.totalMeeting,
//         motherMeetings: meeting.totalMeeting, // All meetings are mother meetings in this context
//         vhsndFacilitation: meeting.totalVhsnd,
//         population: meeting.population,
//         antenatalCare: meeting.antenatal_care,
//         foodHealth: meeting.food_health,
//         hygieneHealth: meeting.hygiene_health,
//         vectorBorneDiseases: meeting.vector_borne_diseases,
//         yogaMentalHealth: meeting.yoga_mental_health,
//         birthSpacing: meeting.birth_spacing,
//         diarrhoea: meeting.diarrhoea,
//         pneumonia: meeting.pneumonia,
//         breastfeeding: meeting.breastfeeding,
//         programIntroduction: meeting.program_introduction
//       }));

//     }

//     // Process school visit data
//     if (data.schoolVisits) {
//       const schoolVisits = data.schoolVisits as SchoolData[];
//       this.schoolData = schoolVisits.map(school => ({
//         name: school.name,
//         totalSchoolVisit: school.totalVisits,
//         totalParticipants: school.totalBoys + school.totalGirls,
//         totalGirls: school.totalGirls,
//         totalBoys: school.totalBoys,
//         oralSessions: school.oralSessions,
//         hygieneSessions: school.hygieneSessions,
//         rallies: school.rallies,
//         totalVisits: school.totalVisits, // Add this
//         state: school.name 
//       }));
//     }

//     if (data.nukkadNatak) {
//       const nukkadNatak = data.nukkadNatak as NukkedNatakData[];
//       this.nukkadNatak = nukkadNatak.map(nukkadNatak => ({
//         name: nukkadNatak.name,
//         totalParticipants: nukkadNatak.totalParticipants,
//         totalMale: nukkadNatak.totalMale,
//         totalFemale: nukkadNatak.totalFemale,
//         totalChild: nukkadNatak.totalChild,
//       }));
//     }

//     // Process event data
//     if (data.events) {
//       this.eventData = data.events.map((event: any) => ({
//         state: event.name,
//         totalEvent: event.totalEvent || 0,
//         totalParticipants: event.totalParticipants || 0,
//         totalMale: event.maleParticipantsCount || 0,
//         totalFemale: event.femaleParticipantsCount || 0,
//         totalChild: event.childParticipantsCount || 0
//       }));
//     }
//   }

//   // Helper method to get state data efficiently
//   getStateData(state: string): StateData | null {
//     const meetingData = this.meetingsData.find(m => m.state === state);
//     if (!meetingData) return null;

//     return {
//       meetingData,
//       motherMeetingTopics: this.motherMeetingTopics[state] || {} as MotherMeetingTopics
//     };
//   }

//   functionFilter(params: any): void {
//     const filterParams = {
//       stateId: params.stateId, 
//       districtId: params.districtId, 
//       blockId: params.blockId, 
//       fromDate: params.fromTimestamp, 
//       toDate: params.toTimestamp, 
//       // designation: params.designation,
//       // designation:this.currentUser.user.designation,
//     };

//    this.loadData(filterParams);
//   }

// }
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommunityInterventionService } from '../Services/community-intervention.service';
import { CommunityInterventionStackGraphComponent } from "../community-intervention-stack-graph/community-intervention-stack-graph.component";
import { ClusterCommunityInterventionStackChartComponent } from "../cluster-community-intervention-stack-chart/cluster-community-intervention-stack-chart.component";
import { CommonModule } from '@angular/common';
import { catchError, forkJoin, Observable, Subject, takeUntil, tap } from 'rxjs';
import { 
  MeetingData, 
  MotherMeetingTopics,
  SchoolData,
  NukkedNatakData,
  EventData,
  StateData,
  MotherMeetingResponse,
  MeetingResponse
} from './community-intervention.types';
import { FilterComponent } from "../filter/filter.component";
import { OverviewDashboardService } from '../Services/overview-dashboard.service';

@Component({
  selector: 'app-community-intervention',
  standalone: true,
  imports: [
    CommunityInterventionStackGraphComponent,
    ClusterCommunityInterventionStackChartComponent,
    CommonModule,
    FilterComponent
  ],
  templateUrl: './community-intervention.component.html',
  styleUrls: ['./community-intervention.component.css'],
})
export class CommunityInterventionComponent implements OnInit, OnDestroy {
  states: string[] = ['Maharashtra', 'Gujarat', 'Rajasthan','Una','Veraval','Talala','Mahuva','Palitana','Bhavnagar','Kumbhalgarh','Rajsamand','Malegaon','Sakri','Girsomnath','Bhavnagar','Washim','Dhule'];
  
  // Data properties
  meetings: MeetingResponse[] = [];
  meetingsData: MeetingData[] = [];
  motherMeetingTopics: Record<string, MotherMeetingTopics> = {};
  schoolData: SchoolData[] = [];
  nukkadNatak: NukkedNatakData[] = [];
  nukkedNatakData: NukkedNatakData[] = [];
  eventData: EventData[] = [];
  currentUser: any;
  params: any = {};

  // UI state properties
  loading = false;
  error: string | null = null;

  private destroy$ = new Subject<void>();
  noDataMessage: any;

  constructor(
    private communityService: CommunityInterventionService,
    private overviewDashboardService: OverviewDashboardService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadData();

    this.overviewDashboardService.params$
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        console.log('Received new params:', params);
        this.params = params;
        if (params) {
          this.functionFilter(params);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadData(params: any = this.params): void {
    console.log('LoadData called with params:', params);
    this.loading = true;
    this.error = null;

    forkJoin({
      meetings: this.communityService.getMeetingData(params).pipe(
        tap(data => console.log('Meetings response:', data)),
        catchError(err => {
          console.error('Meetings data error:', err);
          return [];
        })
      ),
      motherMeetings: this.communityService.getMotherMeetingData(params).pipe(
        tap(data => console.log('Mother meetings response:', data)),
        catchError(err => {
          console.error('Mother meetings data error:', err);
          return [];
        })
      ),
      schoolVisits: this.communityService.getSchoolVisitData(params).pipe(
        tap(data => console.log('School visits response:', data)),
        catchError(err => {
          console.error('School visits data error:', err);
          return [];
        })
      ),
      nukkadNatak: this.communityService.getNukkadNatakData(params).pipe(
        tap(data => console.log('Nukkad natak response:', data)),
        catchError(err => {
          console.error('Nukkad natak data error:', err);
          return [];
        })
      ),
      events: this.communityService.getEventData(params).pipe(
        tap(data => console.log('Events response:', data)),
        catchError(err => {
          console.error('Events data error:', err);
          return [];
        })
      )
    }).pipe(
      takeUntil(this.destroy$),
      catchError(error => {
        this.error = 'Failed to load data. Please try again later.';
        console.error('Data loading error:', error);
        this.loading = false;
        throw error;
      })
    ).subscribe({
      next: (data) => {
        console.log('All data received:', data);
        this.processData(data);
        this.loading = false;
      },
      error: (err) => {
        console.error('Subscription error:', err);
        this.error = 'An error occurred while loading data.';
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  private processData(data: any): void {
    console.log('Processing data:', data);

    // Process meeting data 
    if (data.meetings?.data && Array.isArray(data.meetings.data)) {
      if (data.meetings.data.length === 0) {
        console.warn("No data found for the given criteria");
        this.meetings = []; // Clear the meetings array
        this.noDataMessage = 'No data found for the given criteria'; // Set the "No data" message
      } else {
        console.log('Processing meetings data:', data.meetings.data);
        this.noDataMessage = null; // Clear the "No data" message
        this.meetings = data.meetings.data.map((meeting: MeetingResponse) => ({
          name: meeting.name,
          totalMeeting: meeting.totalMeeting,
          motherMeetingCount: meeting.motherMeetingCount,
          communityMeetingCount: meeting.communityMeetingCount,
          vhsndMeeting: meeting.vhsndMeeting,
          panchayatGharMeetingCount: meeting.panchayatGharMeetingCount,
          totalPopulationCovered: meeting.totalPopulationCovered,
          motherMeetingPopulationCovered: meeting.motherMeetingPopulationCovered,
          communityMeetingPopulationCovered: meeting.communityMeetingPopulationCovered,
          vhsndMeetingPopulationCovered: meeting.vhsndMeetingPopulationCovered,
          panchayatGharMeetingPopulationCovered: meeting.panchayatGharMeetingPopulationCovered,
          totalFemaleCovered: meeting.totalFemaleCovered,
          motherMeetingFemaleCovered: meeting.motherMeetingFemaleCovered,
          communityMeetingFemaleCovered: meeting.communityMeetingFemaleCovered,
          vhsndFemaleCovered: meeting.vhsndFemaleCovered,
          panchayatGharMeetingFemaleCovered: meeting.panchayatGharMeetingFemaleCovered,
        }));
      }
    } else {
      console.warn("meetings data is not in expected format:", data.meetings);
      this.meetings = [];
      this.noDataMessage = "Unexpected data format received";
    }
    

    // Process mother meetings data
    if (data.motherMeetings && Array.isArray(data.motherMeetings)) {
      console.log('Processing mother meetings data:', data.motherMeetings);
      this.meetingsData = data.motherMeetings.map((meeting: MotherMeetingResponse) => ({
        state: meeting.name,
        totalMeetings: meeting.totalMeeting,
        motherMeetings: meeting.totalMeeting,
        vhsndFacilitation: meeting.totalVhsnd,
        population: meeting.population,
        antenatalCare: meeting.antenatal_care,
        foodHealth: meeting.food_health,
        hygieneHealth: meeting.hygiene_health,
        vectorBorneDiseases: meeting.vector_borne_diseases,
        yogaMentalHealth: meeting.yoga_mental_health,
        birthSpacing: meeting.birth_spacing,
        diarrhoea: meeting.diarrhoea,
        pneumonia: meeting.pneumonia,
        breastfeeding: meeting.breastfeeding,
        programIntroduction: meeting.program_introduction
      }));
    } else {
      console.warn("Mother meetings data is not in expected format:", data.motherMeetings);
    }

    // Process school visit data
    if (data.schoolVisits && Array.isArray(data.schoolVisits)) {
      console.log('Processing school visits data:', data.schoolVisits);
      this.schoolData = data.schoolVisits.map((school: SchoolData) => ({
        name: school.name,
        totalSchoolVisit: school.totalVisits,
        totalParticipants: school.totalBoys + school.totalGirls,
        totalGirls: school.totalGirls,
        totalBoys: school.totalBoys,
        oralSessions: school.oralSessions,
        hygieneSessions: school.hygieneSessions,
        rallies: school.rallies,
        totalVisits: school.totalVisits,
        state: school.name 
      }));
    } else {
      console.warn("School visits data is not in expected format:", data.schoolVisits);
    }

    // Process nukkad natak data
    if (data.nukkadNatak && Array.isArray(data.nukkadNatak)) {
      console.log('Processing nukkad natak data:', data.nukkadNatak);
      this.nukkadNatak = data.nukkadNatak.map((natak: NukkedNatakData) => ({
        name: natak.name,
        total:natak.total,
        totalParticipants: natak.totalParticipants,
        totalMale: natak.totalMale,
        totalFemale: natak.totalFemale,
        totalChild: natak.totalChild,
      }));
    } else {
      console.warn("Nukkad natak data is not in expected format:", data.nukkadNatak);
    }

    // Process event data
    if (data.events && Array.isArray(data.events)) {
      console.log('Processing events data:', data.events);
      this.eventData = data.events.map((event: any) => ({
        state: event.name,
        totalEvent: event.totalEvent || 0,
        totalParticipants: event.totalParticipants || 0,
        totalMale: event.maleParticipantsCount || 0,
        totalFemale: event.femaleParticipantsCount || 0,
        totalChild: event.childParticipantsCount || 0
      }));
    } else {
      console.warn("Events data is not in expected format:", data.events);
    }
  }

  getStateData(state: string): StateData | null {
    const meetingData = this.meetingsData.find(m => m.state === state);
    if (!meetingData) {
      console.log(`No meeting data found for state: ${state}`);
      return null;
    }

    return {
      meetingData,
      motherMeetingTopics: this.motherMeetingTopics[state] || {} as MotherMeetingTopics
    };
  }

  functionFilter(params: any): void {
    console.log('Function filter called with params:', params);
    
    const filterParams = {
      stateId: params.stateId, 
      districtId: params.districtId, 
      blockId: params.blockId, 
      fromDate: params.fromTimestamp, 
      toDate: params.toTimestamp
    };

    console.log('Processed filter params:', filterParams);
    this.loadData(filterParams);
  }
}