import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';

@Component({
  selector: 'app-donut-chart-generator',
  standalone: true,
  templateUrl: './donut-chart-generator.component.html',
  styleUrls: ['./donut-chart-generator.component.css'],
})
export class DonutChartGeneratorComponent
  implements AfterViewInit, OnDestroy, OnChanges,OnInit
{
  @Input() chartId!: string; // Chart ID
  @Input() chartData: { country: string; sales: number }[] = []; // Dynamic chart data
  @Input() colors: string[] = []; // Dynamic colors

  private root!: am5.Root;

  /**
   * Reacts to input property changes and re-renders the chart
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && this.chartData.length > 0) {
      console.log('Chart Data Updated:', this.chartData);
      this.generateChart();
    }
    
  }
  ngOnInit() {
    // Ensure the chart has been created before adjusting the legend styles
    if (this.chartData.length > 0) {
      this.generateChart();
    }
  
  
  }

  /**
   * Initializes the chart after the component view is loaded
   */
  ngAfterViewInit() {
    if (this.chartData.length > 0) {
      this.generateChart();
    }
  }

  /**
 * Adjusts legend styles based on screen width
 */
/**
 * Adjusts legend styles based on screen width for amCharts 5
 */

// adjustLegendStyles(legend: am5.Legend) {
//   const screenWidth = window.innerWidth;

//   if (screenWidth < 275 && screenWidth > 20) {
//     // Extra small devices (mobile portrait)
//     legend.labels.template.setAll({
//       fontSize: 15,
//       maxWidth: 120,
//       textAlign: 'left',
//       paddingRight: 15,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//     legend.valueLabels.template.setAll({
//       fontSize: 15,
//       textAlign: 'right',
//       marginLeft: -15,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   }
//   else if (screenWidth < 576 && screenWidth > 280) {
//     // Extra small devices (mobile portrait)
//     legend.labels.template.setAll({
//       fontSize: 5,
//       maxWidth: 120,
//       textAlign: 'left',
//       paddingRight: 50,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//     legend.valueLabels.template.setAll({
//       fontSize: 5,
//       textAlign: 'right',
//       marginLeft: -30,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   } else if (screenWidth >= 576 && screenWidth < 768) {
//     // Small devices (mobile landscape)
//     legend.labels.template.setAll({
//       fontSize: 15,
//       maxWidth: 150,
//       textAlign: 'left',
//       paddingRight: 25,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//     legend.valueLabels.template.setAll({
//       fontSize: 15,
//       textAlign: 'right',
//       marginLeft: -15,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   } else if (screenWidth >= 768 && screenWidth < 1024) {
//     // Medium devices (tablet)
//     legend.labels.template.setAll({
//       fontSize: 15,
//       maxWidth: 250,
//       textAlign: 'left',
//       paddingRight: 30,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//     legend.valueLabels.template.setAll({
//       fontSize: 15,
//       textAlign: 'right',
//       marginLeft: -30,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   } else if (screenWidth >= 1024 && screenWidth < 1440) {
//     // Large devices (desktop)
//     legend.labels.template.setAll({
//       fontSize: 14,
//       maxWidth: 250,
//       textAlign: 'left',
//       paddingRight: 100,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//     legend.valueLabels.template.setAll({
//       fontSize: 14,
//       textAlign: 'right',
//       marginLeft: -40,
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   } else {
//     // Extra large devices (desktop wide)
//     legend.labels.template.setAll({
//       maxWidth: 230,
//       width: 250,
//       fontSize: 14,
//       textAlign: 'left',
//       paddingRight: 105,
    
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });

//     legend.valueLabels.template.setAll({
//       textAlign: 'right',
//       marginLeft: -20,
 
//       oversizedBehavior: 'wrap', // Enable text wrapping for oversized text
//     });
//   }
// }


  /**
   * Creates and updates the donut chart
   */
  generateChart() {
    // Dispose of the existing chart instance if it exists
    if (this.root) {
      this.root.dispose();
    }

    // Initialize amCharts root
    this.root = am5.Root.new(this.chartId);

    // Apply themes
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Disable amCharts logo
    this.root._logo?.dispose();

    // Create the Pie Chart
    const chart = this.root.container.children.push(
      am5percent.PieChart.new(this.root, {
        radius: am5.percent(90),
      innerRadius: am5.percent(50),
      layout: this.root.verticalLayout // Set layout to vertical
      })
    );

    // Create Pie Series
    const series = chart.series.push(
      am5percent.PieSeries.new(this.root, {
        name: 'Series',
        valueField: 'sales',
        categoryField: 'country',
        fillField: 'color', // For dynamic colors
       legendLabelText: "{category}",
       legendValueText: "{value} (	{valuePercentTotal.formatNumber('0.00p')})"
      })
    );

    // Map dynamic colors to the data
    const mappedData = this.chartData.map((item, index) => ({
      ...item,
      color: am5.color(this.colors[index % this.colors.length]),
    }));

    // Set data for the series
    series.data.setAll(mappedData);

    // Hide labels and ticks
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);

    // Apply radial gradient for slices
    series.slices.template.set(
      'fillGradient',
      am5.RadialGradient.new(this.root, {
        stops: [
          { brighten: -0.8 },
          { brighten: -0.8 },
          { brighten: -0.5 },
          { brighten: 0 },
          { brighten: -0.5 },
        ],
      })
    );

    // Add Legend
    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: this.root.verticalLayout,
      })
    );

   
    legend.labels.template.setAll({
      maxWidth: 230,
      width: 250,
      oversizedBehavior: 'wrap',
      fontSize: 14,
      // fontWeight: '500',
      textAlign: 'left',
      paddingRight: 45,
    });

    legend.valueLabels.template.setAll({
      textAlign: 'right',
      marginLeft: -20,
    });

    // Set legend data
    legend.data.setAll(series.dataItems);

    // Adjust legend styles based on screen size
    // this.adjustLegendStyles(legend);

    // Add exporting functionality
    const exporting = am5plugins_exporting.Exporting.new(this.root, {
      filePrefix: 'donut-chart',
      dataSource: this.chartData,
    });

    // Export menu
    am5plugins_exporting.ExportingMenu.new(this.root, {
      exporting,
    });

    // Animate chart appearance
    series.appear(1000, 100);
  }

  /**
   * Clean up chart resources when the component is destroyed
   */
  ngOnDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
