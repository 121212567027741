import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ObservedValuesFromArray, tap } from 'rxjs';
import { environment } from '../../environment/environment.local';

@Injectable({
  providedIn: 'root',
})
export class DiarrheaSelfCareService {
  private readonly endpoint = `${environment.apiUrl}ChildDiarrheas`;
  constructor(private http: HttpClient) {}
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });

  //childDiarrhea Count
  getChildDiarrheaCountFun(params: any): Observable<any> {
    const url =
      `${this.endpoint}/getChildDiarrheaCount/` +
      '?params=' +
      JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  // Service for  diarrhoea First Point Indicator
  diarrhoeaFirstPointIndicator(params: any): Observable<any> {
    const url =
      `${this.endpoint}/diarrhoeaFirstPointIndicator/` +
      '?params=' +
      JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  // Service for Treated At Facilities
  getTreatedAtFacilityApi(params: {}): Observable<any> {
    const url =
      `${this.endpoint}/getTreatedAtFacility/` +
      '?params=' +
      JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  getDiarrheaTreeTableDataApi(params: {}): Observable<any> {
    const url =
      `${this.endpoint}/getDiarrheaTreeTableDataForWeb/` +
      '?params=' +
      JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  getDiarrheaHierarchyData(params: {}): Observable<any> {
    const url = `${this.endpoint}/getDiarrheaHierarchyDataForWeb/`+ '?params='+ JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }

  lowAwarenessVsCouncil():Observable<any>{
    const url = `${this.endpoint}/lowAwarenessVsCouncil`;
    return this.http.get(url,{headers:this.headers})
  }
  getDiarrheaCasesBifurcation(params: {}): Observable<any> {
    const url = `${this.endpoint}/getDiarrheaCasesBifurcation`+ '?params='+ JSON.stringify(params);
    return this.http.get(url, { headers: this.headers });
  }
}

